<template>
    <div class="gb-input-with-icon" :class="classes">
        <span class="gb-input-with-icon__prepend">
            <slot name="prepend" />
        </span>
        <!-- <BaseInput
            v-bind="$attrs"
            class="no-x-padding gb-input--reset"
            @input="$emit('input', $event)"
            @blur="$emit('blur', $event)"
        /> -->
        <input
            v-bind="$attrs"
            @input="$emit('input', $event.target.value)"
            @blur="$emit('blur', $event)"
        />
        <span class="gb-input-with-icon__append">
            <slot name="append" />
        </span>
    </div>
</template>

<script>
export default {
    name: 'InputWithIcon',
    props: {
        isInvalid: {
            type: Boolean,
            default: false
        }
    },
    emits: ['blur', 'input'],
    computed: {
        classes() {
            return {
                'gb-input-with-icon--error': this.isInvalid
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/variables';

.no-x-padding {
    padding-left: 0;
    padding-right: 0;
}
.gb-input--reset {
    border-radius: 0;
    width: 100%;
    box-shadow: none !important;
    &:focus {
        box-shadow: none;
    }
    &:active {
        box-shadow: none;
    }
}
input {
    border: 0;
    padding: 1rem 0;
    color: $primary;
    &:focus,
    &:active {
        outline: 0;
    }
    text-align: center;
    line-height: 1;
    font-size: 1rem;
}
.gb-input-with-icon {
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr);
    border-radius: 5px;
    width: 100%;
    background-color: white;

    &:focus-within {
        box-shadow: 0 0 1px 1px $primary;
    }
    &__prepend,
    &__append {
        margin-left: 1rem;
        margin-right: 1rem;
        display: grid;
        place-items: center;
    }

    &--error {
        box-shadow: 0 0 1px 1px red !important;
    }
}
</style>
