<template>
    <div class="step-3">
        <form class="gb-form" @submit.prevent="onSubmit">
            <fieldset class="gb-form__fieldset">
                <label for="emai">Your email</label>
                <BaseInput
                    id="email"
                    v-model.trim="email"
                    type="email"
                    :is-invalid="!!error"
                    text-align="center"
                    required
                    autofocus
                />
            </fieldset>
            <fieldset class="gb-form__fieldset">
                <label for="password">Password</label>
                <PasswordInput v-model="password" text-align="center" required />
            </fieldset>
            <small v-if="error" class="text--red">{{ error.message }}</small>
            <small>
                Use at least 8 character, one uppercase <br />
                and one number digit.
            </small>
            <Button label="Continue" type="submit" :disabled="loading" primary />
        </form>
    </div>
</template>

<script>
import { CAN_REGISTER } from '@/views/auth/graphql/queries'
import PasswordInput from '@/components/inputs/PasswordInput'

export default {
    name: 'CreateLogin',
    components: {
        PasswordInput
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        }
    },
    emits: ['step'],
    data() {
        return {
            loading: false,
            email: '',
            password: '',
            error: null
        }
    },
    methods: {
        async onSubmit() {
            // Check if user email/password is not taken/valid
            this.loading = true
            try {
                const response = await this.$apollo.query({
                    query: CAN_REGISTER,
                    variables: {
                        email: this.email,
                        password: this.password
                    }
                })
                const { type, errors, success } = response.data.canRegister

                if (type === 'CanRegisterPayload') {
                    if (success) {
                        // email / password is valid, update store with more register data
                        this.$apollo.provider.defaultClient.writeData({
                            data: {
                                registerState: {
                                    __typename: 'RegisterState',
                                    email: this.email,
                                    password: this.password
                                }
                            }
                        })
                        this.$emit('step')
                    }
                }

                if (type === 'ResponseError') {
                    this.error = errors[0]
                }
            } catch (error) {
                console.error(error)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.step-3 {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    form {
        width: 250px;
        display: grid;
        row-gap: 20px;
        padding-left: 0;
        padding-right: 0;
        label {
            display: grid;
            row-gap: 10px;
        }

        input {
            width: 100%;
            margin: 0;
        }
    }
}
</style>
