<template>
    <div class="user-type">
        <div>Are you also an artist or do you have a venue?</div>
        <form @submit.prevent="handleSubmit">
            <div class="checkboxes">
                <Checkbox v-model="isArtist" label="Artist"></Checkbox>
                <Checkbox v-model="isVenue" label="Venue"></Checkbox>
                <Checkbox label="No, just a fan" :value="true" :disabled="true"></Checkbox>
            </div>
            <small>You can select more than one.</small>
            <button type="submit" class="button" :disabled="this.$apollo.loading">
                Complete
            </button>
        </form>
    </div>
</template>

<script>
import Checkbox from '@/components/Checkbox'
import { ME, MY_FAN_PROFILE, MY_ARTIST_PROFILE, MY_VENUE_PROFILE } from '@/graphql/queries/user'
import { UPDATE_MYUSER } from '@/views/auth/graphql/mutations.js'
import logger from '@/utils/logger.js'
export default {
    name: 'UserType',
    components: { Checkbox },
    data() {
        return {
            isArtist: false,
            isVenue: false
        }
    },
    methods: {
        async handleSubmit() {
            try {
                logger.log('Sending update_myuser mutation')
                const response = await this.$apollo.mutate({
                    mutation: UPDATE_MYUSER,
                    variables: {
                        input: {
                            createVenue: this.isVenue,
                            createArtist: this.isArtist
                        }
                    },
                    refetchQueries: [
                        { query: ME },
                        { query: MY_FAN_PROFILE },
                        { query: MY_ARTIST_PROFILE },
                        { query: MY_VENUE_PROFILE }
                    ]
                })
                logger.log('Received response', response)
                const updateResponse = response.data.updateMe
                const { type, errors } = updateResponse
                if (type === 'ResponseError') {
                    // show user error
                    console.log('ResponseError', errors)
                }
                if (type === 'updateMyUserPayload') {
                    // success
                    logger.log('SUCCESS')
                    this.$router.replace('/walkthrough')
                }
            } catch (error) {
                error.graphQLErrors.forEach((error) => {
                    console.log('GraphQL error:', error)
                })
            }
        },
        continue() {
            // create artist and/or venue profile if checked
            // mutations: CREATE_VENUE_PROFILE and CREATE_ARTIST_PROFILE
        }
    }
}
</script>

<style lang="scss" scoped>
.user-type {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    form {
        display: grid;
        justify-content: center;
        width: 250px;
        row-gap: 20px;

        > :nth-child(1) {
            margin-top: 100px;
        }
        > :nth-child(3) {
            margin-top: 90px;
        }
        .checkboxes {
            display: grid;
            row-gap: 20px;
        }

        button {
            margin: 0;
        }
    }
}
</style>
