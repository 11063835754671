<template>
    <div class="step-1">
        <form @submit.prevent="onSubmit">
            <div class="gb-form__fieldset">
                <label for="phone-number"> Mobile number </label>
                <BaseInput
                    id="phone-number"
                    ref="autofocus"
                    v-model.trim="phoneNumber"
                    type="tel"
                    text-align="center"
                    :is-invalid="!!error"
                    required
                    autofocus
                    @input="handleInput"
                />
                <small v-if="error" class="text--red">{{ error.message }}</small>
            </div>
            <Button label="Send me a code" type="submit" :disabled="loading" primary fluid />
            <LoadingEllipsis v-if="loading">Loading</LoadingEllipsis>
            <small v-else> We’ll send you a single-use code<br />to your phone by SMS </small>
        </form>
    </div>
</template>

<script>
import { REGISTER_SMS } from '@/views/auth/graphql/mutations'

export default {
    name: 'PhoneNumber',

    emits: ['step'],

    data() {
        return {
            phoneNumber: '',
            error: null,
            loading: false
        }
    },
    methods: {
        handleInput() {
            if (this.error) {
                this.error = null
            }
        },
        async onSubmit() {
            this.loading = true
            try {
                const response = await this.$apollo.mutate({
                    mutation: REGISTER_SMS,
                    variables: { number: this.phoneNumber }
                })

                const smsResponse = response.data.registerSMS
                const { type, errors } = smsResponse
                if (type === 'ResponseError') {
                    this.error = errors[0]
                }
                if (type === 'RegisterSMSResponse') {
                    this.$apollo.provider.defaultClient.writeData({
                        data: {
                            registerState: {
                                __typename: 'RegisterState',
                                phoneNumber: this.phoneNumber
                            }
                        }
                    })

                    this.$emit('step')
                }
            } catch (error) {
                console.error('ERROR:', error)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.step-1 {
    width: 250px;
    display: grid;
    justify-items: center;
    row-gap: 20px;
    text-align: center;
    form {
        padding-left: 0;
        padding-right: 0;
        display: grid;
        justify-items: center;
        row-gap: 30px;
        .form-input {
            display: grid;
            justify-items: center;
            row-gap: 10px;
        }
        input:not(:placeholder-shown) {
            letter-spacing: 1.8px;
        }
    }
}
</style>
