<template>
    <div class="name">
        <form class="gb-form" @submit.prevent="onSubmit">
            <fieldset class="gb-form__fieldset">
                <label for="full-name">Your full name</label>
                <BaseInput
                    id="full-name"
                    v-model="fullname"
                    required
                    text-align="center"
                    autofocus
                />
            </fieldset>
            <fieldset class="gb-form__fieldset">
                <label for="username">User name</label>
                <BaseInput id="username" v-model="profileName" text-align="center" required />
            </fieldset>
            <Button label="Continue" type="submit" primary />
        </form>
    </div>
</template>

<script>
export default {
    name: 'Name',
    emits: ['step'],
    data() {
        return {
            fullname: '',
            profileName: '',
            loading: false
        }
    },

    methods: {
        async onSubmit() {
            //TODO: validate or check available profile names?
            this.$apollo.provider.defaultClient.writeData({
                data: {
                    registerState: {
                        __typename: 'RegisterState',
                        fullname: this.fullname,
                        profileName: this.profileName
                    }
                }
            })
            this.$emit('step')
        }
    }
}
</script>

<style lang="scss" scoped>
.name {
    text-align: center;
    form {
        width: 250px;
        padding-left: 0;
        padding-right: 0;
        button {
            margin-top: 2rem;
        }
    }
}
</style>
