<template>
    <InputWithIcon
        v-bind="$attrs"
        :type="isVisible ? 'text' : 'password'"
        :pattern="pattern"
        @input="$emit('input', $event)"
        @blur="$emit('blur', $event)"
    >
        <template #append>
            <img
                v-show="!isVisible"
                :style="{ width: '16px' }"
                src="@/assets/icons/show_password_icon.svg"
                @click.prevent="isVisible = true"
            />
            <img
                v-show="isVisible"
                :style="{ width: '16px' }"
                src="@/assets/icons/hide_password_icon.svg"
                @click.prevent="isVisible = false"
            />
        </template>
    </InputWithIcon>
</template>

<script>
import InputWithIcon from '@/components/inputs/InputWithIcon'
export default {
    name: 'PasswordInput',
    components: {
        InputWithIcon
    },
    props: {
        pattern: {
            type: String,
            default: '(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}' // At least one number, one uppercase and lowercase letter, and at least 8 or more chars
        }
    },
    emits: ['input', 'blur'],
    data() {
        return {
            isVisible: false
        }
    }
}
</script>
