<template>
    <div class="step-2">
        <form class="gb-form" autocomplete="none" @submit.prevent="onSubmit">
            <fieldset class="gb-form__fieldset">
                <label for="code">Enter code (6 digits)</label>
                <BaseInput
                    id="code"
                    v-model.trim="code"
                    placeholder="verification code"
                    :is-invalid="!!error"
                    text-align="center"
                    required
                    autofocus
                    @input="handleInput"
                />
                <small v-if="!!error" class="text--red">{{ error.message }}</small>
            </fieldset>
            <Button type="submit" label="Continue" primary />
        </form>
        <LoadingEllipsis v-if="loading">Checking...</LoadingEllipsis>
        <small v-else>
            We’ve just sent you a single-use code
            <br />
            to your phone by SMS
        </small>
    </div>
</template>

<script>
import { VALIDATE_SMS } from '@/views/auth/graphql/mutations'
import gql from 'graphql-tag'

export default {
    name: 'PhoneVerification',

    emits: ['step'],
    apollo: {
        registerState: {
            query: gql`
                query {
                    registerState @client {
                        phoneNumber
                    }
                }
            `
        }
    },
    data() {
        return {
            code: '',
            error: null,
            loading: false
        }
    },
    methods: {
        handleInput() {
            if (this.error) {
                this.error = null
            }
        },
        async onSubmit() {
            this.loading = true
            try {
                const response = await this.$apollo.mutate({
                    mutation: VALIDATE_SMS,
                    variables: {
                        number: this.registerState.phoneNumber,
                        code: parseInt(this.code)
                    }
                })
                const smsResponse = response.data.validateSMSCode
                const { type, errors } = smsResponse
                if (type === 'ResponseError') {
                    // show user error
                    this.error = errors[0]
                }
                if (type === 'RegisterSMSResponse') {
                    this.$apollo.provider.defaultClient.writeData({
                        data: {
                            registerState: {
                                __typename: 'RegisterState',
                                smsCode: this.code
                            }
                        }
                    })
                    this.$emit('step')
                }
            } catch (error) {
                console.log('error', error)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.step-2 {
    text-align: center;
    display: grid;
    row-gap: 20px;
    form {
        width: 250px;
        display: grid;
        row-gap: 2rem;
        padding-left: 0;
        padding-right: 0;
        .form-input {
            display: grid;
            row-gap: 10px;
        }
        input {
            width: 100%;
        }
    }
}
</style>
