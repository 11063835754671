<template>
    <div class="register bg-light text-primary">
        <button class="button button-close" @click="$router.replace('/')"></button>
        <img src="@/assets/logo_primary.svg" alt="" />
        <h2 v-if="step === 5">Now you're a fan!</h2>
        <h2 v-else-if="step === 3">Create login</h2>
        <h2 v-else-if="step === 4">Tell us who you are</h2>
        <h2 v-else>Register user</h2>
        <PhoneNumber v-if="step === 1" @step="step++" @input="phone = $event" />
        <PhoneVerification v-if="step === 2" @step="step++" />
        <CreateLogin v-if="step === 3" @step="step++" />

        <Name v-if="step === 4" @step="register" />

        <UserType v-if="step === 5" @clicked="step++" />
    </div>
</template>

<script>
import { REGISTER_USER } from '../graphql/mutations'

import PhoneNumber from './steps/PhoneNumber'
import PhoneVerification from './steps/PhoneVerification'
import CreateLogin from './steps/CreateLogin'
import UserType from './steps/UserType'
import Name from './steps/Name'
import gql from 'graphql-tag'
import logger from '@/utils/logger.js'
import { IS_LOGGED_IN_QUERY } from '@/graphql/queries/local'
export default {
    name: 'Register',
    components: {
        UserType,
        Name,
        CreateLogin,
        PhoneVerification,
        PhoneNumber
    },
    apollo: {
        isLoggedIn: {
            query: IS_LOGGED_IN_QUERY,
            update({ isLoggedIn }) {
                if (!isLoggedIn) {
                    localStorage.removeItem('profile-context')
                }
                return isLoggedIn
            }
        }
    },

    inject: ['onLogin'],
    data() {
        return {
            step: 1
        }
    },
    methods: {
        async register() {
            logger.log('Sending register mutation')
            const { data } = await this.$apollo.query({
                query: gql`
                    query {
                        registerState {
                            phoneNumber
                            email
                            password
                            smsCode
                            fullname
                            profileName
                        }
                    }
                `
            })

            const response = await this.$apollo.mutate({
                mutation: REGISTER_USER,
                variables: {
                    input: {
                        username: data.registerState.phoneNumber,
                        email: data.registerState.email,
                        password: data.registerState.password,
                        code: data.registerState.smsCode,
                        fullname: data.registerState.fullname,
                        profilename: data.registerState.profileName
                    }
                }
            })

            const userResponse = response.data.registerUser
            logger.log(userResponse)
            const { type, user, errors } = userResponse
            logger.log('Received', type)
            if (type === 'ResponseError') {
                errors.forEach((error) => console.log(error))
            }

            if (type === 'UsersPermissionsLoginPayload') {
                const { jwt } = userResponse

                /* const registerState = JSON.parse(
                    JSON.stringify(data.registerState)
                ) */
                await this.onLogin(jwt, user)
                // this.onLogin()
                logger.log('Registration successful')
                this.step++
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.register {
    height: 100vh;
    display: grid;
    align-content: start;
    row-gap: 1rem;
    justify-items: center;
    padding: 1rem;
    padding-top: 10%;
    > :first-child {
        justify-self: end;
    }
}
</style>
