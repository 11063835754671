import gql from 'graphql-tag'

export const CAN_REGISTER = gql`
    query CanRegister($email: String!, $password: String!) {
        canRegister(email: $email, password: $password) {
            type: __typename
            ... on ResponseError {
                errors {
                    message
                    code
                    field
                }
            }
            ... on CanRegisterPayload {
                success
            }
        }
    }
`
